<template>
  <div class="tags">
    <!--头部组件-->
    <headTabs @name="chooseTab" />
    <van-cell-group>
      <van-field
        placeholder="请输入账号"
        bind:change="onChange"
        v-model="account"
        label="账号"
        @blur="queryUser"
        :maxlength="11"
      />
      <van-field
        placeholder="请输入姓名"
        bind:change="onChange"
        v-model="name"
        label="姓名"
      />
      <van-field
        placeholder="请输入职业证号"
        bind:change="onChange"
        v-model="workAccount"
        label="职业证号"
      />
      <lawFirm @chooseLaw="chooseLaw" direction="up" />
      <van-field
        placeholder="请输入所在区县"
        bind:change="onChange"
        v-model="area"
        label="所在区县"
        @focus="areaFocus"
      />
      <!--地区组件-->
      <chooseArea
        v-if="chooseArea"
        @chooseArea="showAreaData"
        @areaData="getAreaData"
      />
      <!--选择客服-->
      <customer @cusValue="chooseCus" />
    </van-cell-group>
    <div class="footer">
      <van-button
        type="default"
        size="normal "
        style="float: left; width: 50%"
        :square="true"
        @click="register()"
        :disabled="regDis"
        >注册</van-button
      >
      <van-button
        type="info"
        size="normal "
        style="width: 50%"
        :square="true"
        @click="authentication()"
        :disabled="authDis"
        >认证</van-button
      >
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      account: "", //账号
      workAccount: "", //职业证号
      area: "", //所在区县
      chooseArea: false, //是否展示地区选择
      // customerList: [], //客服列表
      cusValue: 0, //选中的客服信息
      name: "", //姓名
      userId: 0,
      jobType: "律师", //用户角色
      regDis:false,
      authDis:false
    };
  },
  methods: {
    //显示区域选择
    areaFocus() {
      this.chooseArea = true;
    },
    //认证
    authentication() {
      if (!this.name) {
        this.$toast("请输入姓名");
        return;
      } else if (!this.workAccount) {
        this.$toast("请输入执业证号");
        return;
      }
      this.$axios
        .post(`${this.$base}/farbun/lawyer/saveLawyerByMySelf`, {
          userId: this.userId,
          lawyerName: this.name,
          lawFirmId: this.lawfirmValue,
          jobNumber: this.workAccount,
          jobType: this.jobType,
          customerVest: this.cusValue,
        })
        .then((res) => {
          this.authDis=true
          this.$toast(res.message);
        });
    },
    //注册
    register() {
      if (!this.name) {
        this.$toast("请输入姓名");
        return;
      }
      this.$axios
        .post(
          `${this.$base}/fission/page/registerUser`,
          {
            account: this.account,
            city: this.area,
            jobNumber: this.workAccount,
            lawFirmName: this.lawfirmValue,
            nickName: this.name,
            customerVest: this.cusValue,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          this.regDis=true
          this.$toast(res.message);
        });
    },
    //根据手机号查询用户
    queryUser() {
      var myreg = /^1(3\d|4[5-9]|5[0-35-9]|6[567]|7[0-8]|8\d|9[0-35-9])\d{8}$/;
      if (!myreg.test(this.account) || !this.account) {
        this.name = "";
        this.$toast("请输入正确的手机号");
        return;
      }
      this.$axios
        .get(`${this.$base}/admin/lb/user?account=${this.account}`)
        .then((res) => {
          if (res.code === 200) {
            this.account = res.data.account;
            this.name = res.data.nickname;
            this.userId = res.data.id;
          }
        });
    },
    //客服组件赋值
    chooseCus(val) {
      this.cusValue = val;
    },
    //地区组件赋值
    getAreaData(val) {
      this.area = val;
    },
    //地区组件变更状态
    showAreaData(val) {
      this.chooseArea = val;
    },
    //律所组件
    chooseLaw(val) {
      this.lawfirmValue = val;
    },
    //头部组件
    chooseTab(val) {
      this.jobType = val;
    },
  },
  mounted() {
    this.account = this.$route.query.phone;
    this.queryUser();
  },
};
</script>
<style scoped>
.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
}
</style>
